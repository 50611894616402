import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "gatsby-theme-cooper-hewitt/src/components/mdx-default-layout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "about-chris-rotunno-"
    }}>{`About Chris Rotunno 🦄👀`}</h1>
    <p>{`This blog is designed to reach `}<em parentName="p">{`potential employers and investors`}</em>{` as well as the general public. I aim to have an online product for people to judge for themselves—independent of social media. This blog is also for people who wish to `}<em parentName="p">{`benefit from my experience in the business environment.`}</em>{` I encourage everyone to check out my posts and to `}<a parentName="p" {...{
        "href": "https://mailchi.mp/ba70d1717e4c/rotunno"
      }}>{`watch for monthly newsletters`}</a>{` with updates and fresh new content.`}</p>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGkk0WCAv/EABwQAAEDBQAAAAAAAAAAAAAAAAABAxMCERIhMv/aAAgBAQABBQKUVwnrQyN2a4//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQMBAT8Byj//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEgH/2gAIAQIBAT8BrVP/xAAbEAACAgMBAAAAAAAAAAAAAAAAAiEyEBEiof/aAAgBAQAGPwKhT0hVxs6WT//EABsQAQACAwEBAAAAAAAAAAAAAAEAESFxgUGR/9oACAEBAAE/IQX57ADC7FigG2OdRLF8I9pP/9oADAMBAAIAAwAAABBk/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxCEv//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAECAQE/EBaf/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIYGhMUFR/9oACAEBAAE/EHY6MALVNPByResC6W7itC8SpDQXZrLPqK0c9T//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "swept away",
          "title": "swept away",
          "src": "/static/07ef65c4ababec13a7e4c163fe438947/00650/dunes.jpg",
          "srcSet": ["/static/07ef65c4ababec13a7e4c163fe438947/0fa0d/dunes.jpg 345w", "/static/07ef65c4ababec13a7e4c163fe438947/8c996/dunes.jpg 690w", "/static/07ef65c4ababec13a7e4c163fe438947/00650/dunes.jpg 1380w", "/static/07ef65c4ababec13a7e4c163fe438947/bfc6b/dunes.jpg 2070w", "/static/07ef65c4ababec13a7e4c163fe438947/73c78/dunes.jpg 2760w", "/static/07ef65c4ababec13a7e4c163fe438947/243ce/dunes.jpg 4032w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "loading": "lazy"
        }}></img>{`
    `}</span><center>{`Great Sand Dunes National Park and Preserve, Colorado 💨 💨`}</center></p>
    <p>{`I grew up being heavily influenced by the cultural idea of individualism. My driving motivational force was leveraged on the idea of what is now considered by Glassdoor and Harvard Business Review to be one of the most coveted job titles in the USA:👔 `}<strong parentName="p">{`Data Scientist`}</strong>{` 👔`}</p>
    <blockquote>
      <p parentName="blockquote">{`As a budding Data Scientist, I rely on the combination of statistics, computer science, and market research to maximize stakeholder’s interests.
I have an extensive background tailored to the financial services sector; working with a variety of financial data including market price data, corporate earnings data, and loan servicing data.`}</p>
    </blockquote>
    <p>{`I have private sector experience working for startups, family run businesses, and corporations.
I drive data science initiatives including the collection, processing, and deployment of data.
I try to understand the mechanics and technicalities of popular algorithms like decision trees and neutral networks to make assumptions about the economy.
I
hope to assemble (or be introduced to) a group of team members who wish to implement data science in the context of cognitive computing for forecasting capital markets. I know—it’s a mouthful!`}</p>
    <p>{`Call me directly if you think we might share interests:`}</p>
    <h2 {...{
      "id": "303-819-4867"
    }}><strong parentName="h2">{`(303) 819-4867`}</strong></h2>
    <h3 {...{
      "id": "lets-get-personal"
    }}>{`Let’s get personal…`}</h3>
    <h4 {...{
      "id": "i-come-from-a-place-where-the-great-plains-meet-the-rocky-mountains-on-the-colorado-front-range"
    }}>{`I come from a place where the Great Plains meet the Rocky Mountains on the Colorado Front Range:`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAbzgZG02b//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhIDE//aAAgBAQABBQLcjrmbgnLbiWvOT//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAAREKExMkH/2gAIAQEABj8C4Zs3EqFH/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAERITFxkaFB/9oACAEBAAE/IW2/YlWCwJ7dDtwaFAEsIh9ekf/aAAwDAQACAAMAAAAQLA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAHBABAAICAwEAAAAAAAAAAAAAAQAhEUFxgdHw/9oACAEBAAE/EM93UgSt2mFoccJixPxqOkZ2OYrRFbrwn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "home",
          "title": "home",
          "src": "/static/3953160ef3e01de3df77f7c56c55b55c/00650/mountainss.jpg",
          "srcSet": ["/static/3953160ef3e01de3df77f7c56c55b55c/0fa0d/mountainss.jpg 345w", "/static/3953160ef3e01de3df77f7c56c55b55c/8c996/mountainss.jpg 690w", "/static/3953160ef3e01de3df77f7c56c55b55c/00650/mountainss.jpg 1380w", "/static/3953160ef3e01de3df77f7c56c55b55c/bfc6b/mountainss.jpg 2070w", "/static/3953160ef3e01de3df77f7c56c55b55c/73c78/mountainss.jpg 2760w", "/static/3953160ef3e01de3df77f7c56c55b55c/243ce/mountainss.jpg 4032w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "loading": "lazy"
        }}></img>{`
    `}</span>{` `}<center>{`Pella Crossing, Colorado ❄️`}</center></p>
    <h4 {...{
      "id": "i-live-in-saint-petersburg-florida-where-the-pinnellas-peninsula-borders-tampa-bay"
    }}>{`I live in Saint Petersburg, Florida, where the Pinnellas Peninsula borders Tampa Bay:`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAemcavNCD//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhIRE//aAAgBAQABBQJWh0jpmDEHjB//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEREv/aAAgBAgEBPwG2pf/EABkQAAMAAwAAAAAAAAAAAAAAAAABMRAykf/aAAgBAQAGPwKrOpCPp//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExgZHR/9oACAEBAAE/IfFRWbiFYWaHEdFe0jZJ/9oADAMBAAIAAwAAABBH3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAARYf/aAAgBAgEBPxC2D//EABwQAQEBAQACAwAAAAAAAAAAAAERACExQWGh0f/aAAgBAQABPxApBJxFdUCDWpkZ1mYS749vrN4oM5+jectn/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "new home",
          "title": "new home",
          "src": "/static/6c9e2ee069013d38aa3bce6dd16becc5/00650/EmptyBeach.jpg",
          "srcSet": ["/static/6c9e2ee069013d38aa3bce6dd16becc5/0fa0d/EmptyBeach.jpg 345w", "/static/6c9e2ee069013d38aa3bce6dd16becc5/8c996/EmptyBeach.jpg 690w", "/static/6c9e2ee069013d38aa3bce6dd16becc5/00650/EmptyBeach.jpg 1380w", "/static/6c9e2ee069013d38aa3bce6dd16becc5/bfc6b/EmptyBeach.jpg 2070w", "/static/6c9e2ee069013d38aa3bce6dd16becc5/73c78/EmptyBeach.jpg 2760w", "/static/6c9e2ee069013d38aa3bce6dd16becc5/243ce/EmptyBeach.jpg 4032w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "loading": "lazy"
        }}></img>{`
    `}</span>{` `}<center>{`Fort Desoto Dog Park, Florida 🌊`}</center></p>
    {
      /* #### Now I continue to take the path less traveled hoping to find hidden data treasure with "me mateys":
      ![treasure](treasure.jpg) <center>Sawgrass Lake Park, Florida © Chris Rotunno 💰</center> */
    }
    <h4 {...{
      "id": "heres-scannon-my-great-companion"
    }}>{`Here’s Scannon, my Great Companion:`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAGnHozSqSep7J4HmEf/xAAbEAACAgMBAAAAAAAAAAAAAAAAAgERAyExMv/aAAgBAQABBQLJ5vcNY8641jmaKc//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAwEBPwEj/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQIBAT8BY//EABkQAAIDAQAAAAAAAAAAAAAAAAABECFRIP/aAAgBAQAGPwJmlNLmsj//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhMXFh/9oACAEBAAE/IUiBYYkyQ8hkF+ImWq47JHNtucnuhemu/9oADAMBAAIAAwAAABAgMD3/xAAZEQACAwEAAAAAAAAAAAAAAAAAARARITH/2gAIAQMBAT8QTChcj//EABgRAAMBAQAAAAAAAAAAAAAAAAABERBB/9oACAECAQE/EHTKdz//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMUFhceHx/9oACAEBAAE/EFvJGTnGIo4Lv8yUB7R8ykZA7cWjQ13zqXziJQF0XjFEdgwtoDAuhuDTP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "dog",
          "title": "dog",
          "src": "/static/ccf2f31d18bf620d7697027882e6c822/00650/scannon.jpg",
          "srcSet": ["/static/ccf2f31d18bf620d7697027882e6c822/0fa0d/scannon.jpg 345w", "/static/ccf2f31d18bf620d7697027882e6c822/8c996/scannon.jpg 690w", "/static/ccf2f31d18bf620d7697027882e6c822/00650/scannon.jpg 1380w", "/static/ccf2f31d18bf620d7697027882e6c822/bfc6b/scannon.jpg 2070w", "/static/ccf2f31d18bf620d7697027882e6c822/73c78/scannon.jpg 2760w", "/static/ccf2f31d18bf620d7697027882e6c822/aa4a0/scannon.jpg 3020w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "loading": "lazy"
        }}></img>{`
    `}</span>{` `}<center>{` At the Apartment 🐶`}</center></p>
    <h4 {...{
      "id": "heres-the-fruit-of-my-loins"
    }}>{`Here’s the Fruit of my Loins:`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgADBP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHkY0mjOZ//xAAZEAACAwEAAAAAAAAAAAAAAAABAgASIUH/2gAIAQEAAQUC5bFwWMXVYgH/xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/AcVD/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BJ//EABsQAAIBBQAAAAAAAAAAAAAAAAARARAxMlFh/9oACAEBAAY/AmLV6z0xP//EABwQAAICAgMAAAAAAAAAAAAAAAERACFBcYHB0f/aAAgBAQABPyEdWoStAwT2EaDDQqtRSEslEReKn//aAAwDAQACAAMAAAAQzP8A/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIUH/2gAIAQMBAT8QmU+Dth//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQBB/9oACAECAQE/EAWzQeb/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAxIUFhUYH/2gAIAQEAAT8QDQBUQJt9ygz6FEp2wqTNzCUwap5lC5BPCzWBIeO6H8z/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "children",
          "title": "children",
          "src": "/static/d9702a8e8c62422b54e09732ffdcb80e/00650/kids.jpg",
          "srcSet": ["/static/d9702a8e8c62422b54e09732ffdcb80e/0fa0d/kids.jpg 345w", "/static/d9702a8e8c62422b54e09732ffdcb80e/8c996/kids.jpg 690w", "/static/d9702a8e8c62422b54e09732ffdcb80e/00650/kids.jpg 1380w", "/static/d9702a8e8c62422b54e09732ffdcb80e/bfc6b/kids.jpg 2070w", "/static/d9702a8e8c62422b54e09732ffdcb80e/73c78/kids.jpg 2760w", "/static/d9702a8e8c62422b54e09732ffdcb80e/ffbed/kids.jpg 6000w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "loading": "lazy"
        }}></img>{`
    `}</span>{` `}<center>{` Riley (left) and Bailey (right) 🌳`}</center></p>
    <p><a parentName="p" {...{
        "href": "http://rotunno.io/about"
      }}>{`Back to Top`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://rotunno.io/"
      }}>{`Back to Blog`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      